<template>
  <main class="container">
    <div class="steps">
      <div class="step" :class="{ inactive: activeStep === 'Your funding' }">
        <div class="circle" :class="{ active: activeStep === 'Your funding' }"></div>
        <p :class="{ active: activeStep === 'Your funding' }">1. Your funding</p>
      </div>
      <div class="step inactive">
        <div class="circle"></div>
        <p :class="{ active: activeStep === 'Your business' }">2. Your business</p>
      </div>

      <div class="step inactive" v-if="!isExternalGroup">
        <div class="circle"></div>
        <p :class="{ active: activeStep === 'Your matches' }">3. Your matches</p>
      </div>
    </div>
    <div class="content" v-if="showIntegrations && companyId">
      <AlertIntegrations />
    </div>
    <router-view :companyId="companyId" @save-success="saveSuccess" @show-integration="showIntegrations" />
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { notificationRoutine } from '@/store/modules/notification/routines'
import { companyGetters } from '@/store/modules/company/routines'
import { authGetters } from '@/store/modules/auth/routines'
import AlertIntegrations from '@/components/AlertIntegrations'
import { toastDefaultOptions } from '@/config/vue-toast'

export default {
  components: { AlertIntegrations },
  props: {
    activeStep: {
      type: String,
      default: 'Your business'
    },
    showIntegrations: {
      type: Boolean,
      default: true
    },
    showYourMatches: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isExternalGroup: authGetters.IS_EXTERNAL_GROUP,
      companyId: companyGetters.COMPANY_ID,
      currentCompanyId: companyGetters.COMPANY_CURRENT_ID
    })
  },
  methods: {
    ...mapActions({
      notify: notificationRoutine.TRIGGER
    }),
    getNotificationText() {
      if (this.isExternalGroup) return "Great you're all done! Your Advisor will be in touch to discuss your potential funding matches in more detail."
      return 'Details updated'
    },
    saveSuccess() {
      this.$toasted.show(this.getNotificationText(), {
        ...toastDefaultOptions,
        className: this.isExternalGroup ? 'text-center' : '',
        type: 'success',
        icon: 'check',
        duration: this.isExternalGroup ? 5000 : 2000
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.container {
  max-width: 900px;
  padding-top: 40px;
  padding-bottom: 1rem;

  .steps {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;

    .step {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      border-bottom: 2px solid $color-matches-form-steps-active;

      .circle {
        width: 10px;
        height: 10px;
        position: absolute;
        left: -5px;
        bottom: -5px;
        margin-bottom: -1px;
        border-radius: 50%;
        background-color: $color-matches-form-steps-active;
      }

      &.active p {
        color: $color-matches-form-steps-active;
      }

      &.inactive {
        border-bottom-color: $color-matches-form-steps-inactive;

        .circle {
          margin-bottom: -1px;
          border: 2px solid $color-matches-form-steps-inactive;
          background-color: $color-white;

          &.active {
            border: 2px solid $color-matches-form-steps-active;
            background-color: $color-matches-form-steps-active;
          }
        }

        p {
          font-size: 14px;
          font-weight: 400;
          color: var(--color-primary-300);

          &.active {
            color: $color-primary;
          }
        }
      }

      p {
        width: 200px;
        position: absolute;
        left: -5px;
        bottom: -30px;
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        color: $color-primary;
      }
    }
  }

  .content {
    margin-top: 74px;

    /deep/.alert {
      margin-bottom: 32px;
    }

    .controls {
      display: flex;
      justify-content: space-between;

      .btn {
        height: 56px;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-sm) {
    .steps .step {
      p {
        display: none;
      }

      &:last-child {
        width: auto;
      }
    }
  }
}
</style>
